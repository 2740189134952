
















import {Component, Vue} from "vue-property-decorator";
import {ApiKeqingBotSetCommandProtect} from "@/request-apis/keqing-helper/cmd/system/command-protect";
import {IApiKeqingBotGetCommandsListCommandItem} from "@/request-apis/keqing-helper/cmd/system/commands-list";

@Component
export default class CompCommandProtected extends Vue {
    private protectedCmdItem:IApiKeqingBotGetCommandsListCommandItem | null = null;
    private title:string = "";
    private isProtectedCmdOpen:boolean = false;
    private protectedCmdContent:string = "";
    private protectedLoading:boolean = false;

    public open(item:IApiKeqingBotGetCommandsListCommandItem) {
        this.protectedCmdItem = item;
        this.title = `维护 [#${item.label}](${item.id}) 指令`
        this.protectedCmdContent = item.protectMsg ? item.protectMsg : "";
        this.isProtectedCmdOpen = true;
    }

    private onProtectedCmdClick() {
        if(this.protectedCmdItem) {
            this.protectedLoading = true;
            new ApiKeqingBotSetCommandProtect({
                cmd: this.protectedCmdItem.id,
                msg: this.protectedCmdContent,
            }).run().then(result => {
                this.$message.success(`设置成功`);
                this.protectedLoading = false;
                if(this.protectedCmdItem) {
                    this.protectedCmdItem.protected = true;
                    this.protectedCmdItem.protectMsg = this.protectedCmdContent;
                }
                this.onProtectedCmdCancel();
            }).catch(e => {
                this.$message.error(`${e.msg}(${e.code})`);
                this.protectedLoading = false;
            })
        }
    }

    private onProtectedCmdCancel() {
        this.isProtectedCmdOpen = false;
        this.protectedCmdContent = "";
        this.protectedCmdItem = null;
    }

}
