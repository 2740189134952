








































import {Component, Vue, Watch} from "vue-property-decorator";
import {ApiKeqingBotPermissionsRemoveGlobalBwlist} from "@/request-apis/keqing-helper/cmd/system/global-bwlist-remove";
import {ApiKeqingBotPermissionsGetGlobalBwlist} from "@/request-apis/keqing-helper/cmd/system/global-bwlist";
import {ApiKeqingBotPermissionsAddGlobalBwlist} from "@/request-apis/keqing-helper/cmd/system/global-bwlist-add";

@Component
export default class ViewBwListManage extends Vue {
    private isLoading:boolean = false;
    private currentTabKey:string = '';
    private tabs:{key:string, title:string}[] = [
        {
            key: 'qq',
            title: "QQ黑名单",
        },
        {
            key: 'group',
            title: "群黑名单",
        }
    ];
    private tags:Array<number> = [];
    private inputVisible:boolean = false;
    private inputValue:string =  '';
    private tagListError:{code:number,msg:string} | null = null;

    private mounted() {
        this.inputVisible = false;
        this.inputValue = '';
        this.currentTabKey = 'qq';
        this.refreshTags();
    }

    private onTabChanged() {
        this.refreshTags();
    }

    private onTagCloseClick(target:number) {
        new ApiKeqingBotPermissionsRemoveGlobalBwlist({
            type: this.currentTabKey,
            target: target,
        }).run().then(result => {
            this.$message.success(`删除[${target}]成功`);
        }).catch(e => {
            this.$message.error(`${e.msg}(${e.code})`);
        }).finally(() => {
            this.refreshTags();
            this.isLoading = false;
        })
        this.isLoading = true;
    }

    private handleInputChange(e:any) {
        this.inputValue = e.target.value;
    }

    private handleInputConfirm() {
        if(this.inputValue == '') {
            this.inputVisible = false;
            return;
        }
        let inputValue = parseInt(this.inputValue);
        if(isNaN(inputValue)) {
            this.inputVisible = false;
            this.inputValue = '';
            this.$message.error("输入内容不合法");
            return;
        }
        if (inputValue && this.tags.indexOf(inputValue) === -1) {
            this.isLoading = true;
            new ApiKeqingBotPermissionsAddGlobalBwlist({
                type: this.currentTabKey,
                target: inputValue
            }).run().then(result => {
                this.tags.push(inputValue)
                this.$message.success('添加成功');
            }).catch(e => {
                this.$message.error(`${e.msg}(${e.code})`);
            }).finally(() => {
                this.inputVisible = false;
                this.inputValue = '';
                this.isLoading = false;
            })
        }
    }

    private refreshTags() {
        let key = this.currentTabKey;
        this.tags = [];
        this.isLoading = true;
        new ApiKeqingBotPermissionsGetGlobalBwlist({
            type: key,
        }).run().then(result => {
            this.tags = result;
            this.tagListError = null;
            this.isLoading = false;
        }).catch(e => {
            this.tagListError = e;
            this.isLoading = false;
        })

    }
}
