import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotFuncList extends ApiKeqingBotCmd<IApiKeqingBotFuncListResult> implements IApi {
    public moduleId = "system";
    public action = "func-list";
    public constructor() {
        super();
    }
}

export type IApiKeqingBotFuncListResult = Array<IApiKeqingBotFuncListItem>;

export type IApiKeqingBotFuncListItem = {
    key:string,
    type:string,
    icon:string,
    title:string,
    child?:IApiKeqingBotFuncListItem[],
}