import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";
import IApi from "@/managers/net/i-api";

export enum SentenceType {
    Subject = 'subject',
    Predicate = 'predicate',
    Object = 'object',
}

export type RandomSentenceListResult = {
    page: number,
    total: number,
    data: string[],
    permission: {
        add: boolean,
        remove: boolean,
    }
}

export default class ApiKeqingBotRandomSentenceGetList extends ApiKeqingBotCmd<RandomSentenceListResult> implements IApi {
    public moduleId = "moe.yumi.random";
    public action = "sentence-list";
    public constructor(type: SentenceType, page:number = 1, count:number = 50) {
        super({
            type, page, count,
        });
    }
}




