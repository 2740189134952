













































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import UserModule from "@/components/modules/UserModule.vue";
import UserManager, { UserEvent } from '@/managers/user-manager';
import ViewDashboard from "@/components/admin/view-dashboard.vue";
import ViewQTargetList from "@/components/admin/view-qq-target-list.vue";
import UserHead from "@/components/user-head.vue";
import ViewCommandManage from "@/components/admin/view-command-manage.vue";
import ViewBwListManage from "@/components/admin/view-bw-list-manage.vue";
import ViewPermissionsManage from "@/components/admin/view-permissions-manage.vue";
import ViewModuleManage from "@/components/admin/view-module-manage.vue";
import {ApiKeqingBotFuncList, IApiKeqingBotFuncListItem} from "@/request-apis/keqing-helper/cmd/system/func-list";
import BotManager from "@/managers/bot-manager";
import CompBotList from "@/components/admin/comp-bot-list.vue";
import ViewWolConfig from "@/components/admin/view-wol-config.vue";
import ViewRandomOrderManage from "@/components/admin/view-random-order-manage.vue";
import ViewRandomSentenceManage from "@/components/admin/view-random-sentence-manage.vue";
import ViewSetuConfig from "@/components/admin/view-setu-config.vue";

enum ViewStates {
    Loading = 'loading',
    BotList = 'bot-list',
    Control = 'control',
    Login = 'login',
}

@Component({
    components: {
        ViewSetuConfig,
        ViewRandomSentenceManage,
        ViewRandomOrderManage,
        ViewWolConfig,
        CompBotList,
        ViewModuleManage,
        ViewPermissionsManage,
        ViewBwListManage, ViewCommandManage,  UserHead, ViewDashboard, ViewQTargetList ,UserModule
    }
})
export default class AdminView extends Vue {
    private collapsed:boolean = false;
    private loadingState:number = 0;
    private viewStates:ViewStates = ViewStates.Loading;
    private funcList:IApiKeqingBotFuncListItem[] = [];
    private menuDataSource:IApiKeqingBotFuncListItem[] | null = null;
    private currentSelectItem:string[] = [];
    private defaultOpenKeys:string[] = [];
    private defaultSelectedKeys:string[] = [];
    private botsListError:string | undefined;
    private currentOpBot:string | undefined = undefined;
    private isLogin:boolean = false;

    private beforeCreate() {
        document.title = "刻晴小助手后台管理";
    }

    private mounted() {
        this.loadingState = 1;
        UserManager.getInstance().on(UserEvent.ON_LOGIN_STATE_REFRESH, this, this.onLoginStateRefresh);
    }

    private onLoginStateRefresh() {
        if(this.isLogin) {
            let loginInfo = UserManager.getInstance().getLoginUserInfo();
            if(!loginInfo) {
                window.location.reload();
                return;
            }
        }
        if(this.loadingState == 1) {
            this.checkLoadingState();
        }
    }

    private nextLoadingState() {
        this.loadingState ++;
        this.checkLoadingState();
    }

    private finishLoadingState() {
        this.loadingState = 0;
    }

    private checkLoadingState() {
        switch(this.loadingState) {
            case 1: {
                this.viewStates = ViewStates.Loading;
                let loginInfo = UserManager.getInstance().getLoginUserInfo();
                if(!loginInfo) {
                    this.viewStates = ViewStates.Login;
                    return;
                } else {
                    this.isLogin = true;
                    this.nextLoadingState();
                }
                break;
            }

            case 2: {
                if(UserManager.getInstance().getIsBindQQ()) {
                    this.nextLoadingState();
                } else {
                    UserManager.getInstance().popupAuthQQ();
                }
                break;
            }

            case 3: {
                this.botsListError = undefined;
                let opBotId:number | undefined = BotManager.getInstance().currentSelectBot;
                if(opBotId == undefined) {
                    this.viewStates = ViewStates.BotList;
                } else {
                    BotManager.getInstance().getBotStatesInfo(opBotId).then(result => {
                        if(result.states == 'online') {
                            this.currentOpBot = result.nickname;
                            this.nextLoadingState();
                        } else {
                            this.botsListError = `[${result.nickname}]处于离线状态，请选择其他机器人`;
                            this.viewStates = ViewStates.BotList;
                            BotManager.getInstance().setBot(-1);
                        }
                    }).catch(e => {
                        switch(e.code) {
                            case 4005: {
                                this.botsListError = `机器人资料获取失败，请选择其他机器人`;
                                BotManager.getInstance().setBot(-1);
                                break;
                            }

                            default: {
                                this.botsListError = e.msg;
                                break;
                            }
                        }
                        this.viewStates = ViewStates.BotList;
                    });
                }
                break;
            }

            case 4: {
                new ApiKeqingBotFuncList().run().then(req => {
                    this.funcList = req;
                }).catch(reason => {
                    this.funcList = [];
                }).finally(() => {
                    this.nextLoadingState();
                })
                break;
            }

            case 5: {
                if(this.funcList.length > 0) {
                    let func = (item:IApiKeqingBotFuncListItem) => {
                        if(item.child && item.child.length > 0) {
                            func(item.child[0]);
                            this.defaultOpenKeys[0] = item.key;
                        } else {
                            this.defaultSelectedKeys[0] = item.key;
                        }
                    }
                    func(this.funcList[0]);
                    this.currentSelectItem[0] = this.defaultSelectedKeys[0];
                }
                this.menuDataSource = this.funcList;
                this.funcList = [];
                this.viewStates = ViewStates.Control;
                this.finishLoadingState();
                break;
            }
        }
    }

    @Watch('currentSelectItem')
    private onFuncMenuItemClick(node:any) {
        console.log(this.currentSelectItem)
    }

    private onBotListSelect(botId:number, botName:string) {
        BotManager.getInstance().setBot(botId);
        this.currentOpBot = botName;
        this.viewStates = ViewStates.Loading;
        this.nextLoadingState();
    }

    private onSelectItemChanged(key:string, oldKey:string) {
        console.log(key);
    }

    private onChangeBotClick() {
        this.jumpToSelectBotList();
    }

    private onBotShutDown() {
        this.jumpToSelectBotList();
    }

    private onLoginClick() {
        UserManager.getInstance().popupLogin('jump', window.location.href);
    }

    private jumpToSelectBotList() {
        BotManager.getInstance().setBot(-1);
        this.currentOpBot = undefined;
        this.viewStates = ViewStates.Loading;
        this.loadingState = 3;
        this.checkLoadingState();
    }
}
