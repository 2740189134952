import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";
import IApi from "@/managers/net/i-api";


export type RandomOrderListResult = {
    page: number,
    total: number,
    data: string[],
    permission: {
        add: boolean,
        remove: boolean,
    }
}

export default class ApiKeqingBotRandomOrderGetList extends ApiKeqingBotCmd<RandomOrderListResult> implements IApi {
    public moduleId = "moe.yumi.random";
    public action = "order-list";
    public constructor(page:number = 1, count:number = 50) {
        super({
            page, count,
        });
    }
}




