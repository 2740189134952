



















enum States {
    Loading = 'loading',
    NoTargetsData = 'no-targets-data',
    Finished = 'finished',
}

import {
    ApiKeqingBotGetFriendList,
    IApiKeqingBotFriendItemData
} from "@/request-apis/keqing-helper/cmd/system/friend-list";
import {ApiKeqingBotGetGroupList} from "@/request-apis/keqing-helper/cmd/system/group-list";
import {Component, Model, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class CompTargetSelector extends Vue {
    @Model('input') private propSelectTargets!:number[];
    @Prop({required: true}) type!:string;
    private states:States = States.Loading;
    private error:string = "";
    private targetsList:{id:number, name:string}[] = [];
    private selectTargets:number[] = [];

    private mounted():void {
        this.states = States.Loading;
        this.selectTargets = this.propSelectTargets;
        switch(this.type) {
            case 'friend': {
                new ApiKeqingBotGetFriendList().run().then(data => {
                    this.targetsList = data.list.map(item => {
                        return {id:item.user_id, name:item.nickname};
                    });
                    this.states = States.Finished;
                }).catch(e => {
                    this.error = `${e.msg}(${e.code})`;
                    this.states = States.NoTargetsData;
                })
                break;
            }

            case 'group': {
                new ApiKeqingBotGetGroupList().run().then(data => {
                    this.targetsList = data.list.map(item => {
                        return {id:item.group_id, name:item.group_name};
                    });
                    this.states = States.Finished;
                }).catch(e => {
                    this.error = `${e.msg}(${e.code})`;
                    this.states = States.NoTargetsData;
                })
                break;
            }
        }
    }

    @Watch('selectTargets', {deep: true, immediate: true})
    private onSelectTargetChanged(val:number[]) {
        if(this.states != States.Finished) return;
        this.$emit('input', val);
        this.$emit('onChanged', val);
    }

}
