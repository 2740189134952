import {BotStatesInfo} from "@/request-apis/keqing-helper/bots";
import Api, {ApiMethod} from "@/managers/net/Api";
import IApi from "@/managers/net/i-api";

export class ApiKeqingBotStates extends Api<BotStatesInfo> implements IApi {
    public path = "/keqing-helper/bot-states";
    public method = ApiMethod.GET;
    public constructor(id:number) {
        super({id:id+''});
    }
}