import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";
import IApi from "@/managers/net/i-api";
import {SentenceType} from "@/request-apis/keqing-helper/cmd/moe.yumi.random/sentence-list";


export default class ApiKeqingBotRandomSentenceDelete extends ApiKeqingBotCmd<void> implements IApi {
    public moduleId = "moe.yumi.random";
    public action = "sentence-delete";
    public constructor(type:SentenceType, sentence:string, isAddToDeletedList:boolean) {
        super({type, sentence, isAddToDeletedList});
    }
}


