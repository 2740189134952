

















































import {Component, Vue} from "vue-property-decorator";
import {Utils} from "@/utils/utils";
import {
    ApiKeqingBotDashboardInfo,
    IApiKeqingBotDashboardInfoResult
} from "@/request-apis/keqing-helper/cmd/system/dashboard-info";
import {ApiKeqingBotDoRefreshData} from "@/request-apis/keqing-helper/cmd/system/refresh-data";
import {ApiKeqingBotReboot} from "@/request-apis/keqing-helper/cmd/system/reboot";
import {ApiKeqingBotStates} from "@/request-apis/keqing-helper/bot-states";
import BotManager from "@/managers/bot-manager";
import {ApiKeqingBotShutdown} from "@/request-apis/keqing-helper/cmd/system/shutdown";



@Component
export default class ViewDashboard extends Vue {
    private runTimeTimeout:number = NaN;
    private botInfo:IApiKeqingBotDashboardInfoResult | null = null;
    private runTime:string = "";
    private loadingVisible:boolean = false;
    private loadingHint:string = "";

    public mounted() {
        this.refreshInfo();
    }

    public destroyed() {
        if(!isNaN(this.runTimeTimeout)) {
            clearInterval(this.runTimeTimeout);
            this.runTimeTimeout = NaN;
        }
    }

    private onReloadDataClick() {
        let key = 'api-reload-data';
        this.$message.loading({
            content: "正在刷新数据...",
            key: key
        });
        new ApiKeqingBotDoRefreshData().run().then(data => {
            this.$message.success({
                content: "刷新成功",
                key: key,
            })
        }).catch(e => {
            this.$message.error({
                content: `${e.msg}(${e.code})`,
                key: key,
            })
        })
    }

    private onRebootClick() {
        this.$confirm({
            title: '重新启动',
            content: '确定要重新启动刻晴小助手？',
            onOk:() => {
                this.doReboot();
            },
            onCancel() {},
        });
    }

    private onShutdownClick() {
        this.$confirm({
            title: '关闭',
            content: '确定要关闭刻晴小助手？',
            onOk:() => {
                this.doShutdown();
            },
            onCancel:()=> {

            },
        });
    }

    private async doReboot() {
        this.loadingVisible = true;
        this.loadingHint = "正在重启，请稍候...";
        try {
            let result = await new ApiKeqingBotReboot().run();
            if(!result) {
                throw 'api访问失败';
            }
            let checkStates = true;
            let curBot = BotManager.getInstance().currentSelectBot;
            if(curBot == undefined) {
                throw '未找到当前操作的机器人';
            }
            await Utils.sleep(1000);
            while(checkStates) {
                await Utils.sleep(1500);
                let states = await new ApiKeqingBotStates(curBot).run();
                if(states.states == 'online') {
                    break;
                }
            }
            this.botInfo = null;
            this.refreshInfo();
        } catch (e) {
            if(typeof e == 'string') {
                this.$message.error(e);
            } else if('code' in e && 'msg' in e) {
                this.$message.error(`${e.msg}(${e.code})`);
            }
        } finally {
            this.loadingVisible = false;
            this.loadingHint = "";
        }
    }

    private async doShutdown() {
        this.loadingVisible = true;
        this.loadingHint = "正在关闭，请稍候...";
        try {
            let result = await new ApiKeqingBotShutdown().run();
            if(!result) {
                throw 'api访问失败';
            }
            let checkStates = true;
            let curBot = BotManager.getInstance().currentSelectBot;
            if(curBot == undefined) {
                throw '未找到当前操作的机器人';
            }
            await Utils.sleep(1000);
            while(checkStates) {
                await Utils.sleep(1500);
                let states = await new ApiKeqingBotStates(curBot).run();
                if(states.states == 'offline') {
                    break;
                }
            }
            this.$emit('onBotShutDown');
        } catch (e) {
            if(typeof e == 'string') {
                this.$message.error(e);
            } else if('code' in e && 'msg' in e) {
                this.$message.error(`${e.msg}(${e.code})`);
            }
        } finally {
            this.loadingVisible = false;
            this.loadingHint = "";
        }
    }

    private refreshInfo() {
        new ApiKeqingBotDashboardInfo().run().then(data => {
            this.botInfo = data;
            console.log(data);
            this.refreshRunTime();
            if(!isNaN(this.runTimeTimeout)) {
                clearInterval(this.runTimeTimeout);
                this.runTimeTimeout = NaN;
            }
            this.runTimeTimeout = setInterval(() => {
                this.refreshRunTime();
            },1000);
        });
    }

    private refreshRunTime() {
        let curTime = new Date().getTime();
        if(!this.botInfo) return;
        let startTime = this.botInfo.systemInfo.startTime;
        this.runTime = Utils.transSecond2DayHourMinuteSecond((curTime - startTime) / 1000);
    }
}
