import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotGetGroupList extends ApiKeqingBotCmd<IApiKeqingBotGetGroupListResult> implements IApi {
    public moduleId = "system";
    public action = "group-list";
    public constructor() {
        super();
    }
}

export type IApiKeqingBotGetGroupListResult = {
    permission:{
        sendMsg:boolean,
        delete:boolean
    },
    list:IApiKeqingBotGroupItemData[]
}

export type IApiKeqingBotGroupItemData = {
    group_id:number,
    group_name:string,
    group_memo:string,
    group_create_time:number,
    group_level:number,
    max_member_count:number,
    member_count:number,
}