








































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {ApiKeqingBotSendGroupMessage} from "@/request-apis/keqing-helper/cmd/system/qq-send-group-message";
import {ApiKeqingBotGetGroupList, IApiKeqingBotGroupItemData} from "@/request-apis/keqing-helper/cmd/system/group-list";
import {ApiKeqingBotSendFriendMessage} from "@/request-apis/keqing-helper/cmd/system/qq-send-friend-message";
import {
    ApiKeqingBotGetFriendList,
    IApiKeqingBotFriendItemData
} from "@/request-apis/keqing-helper/cmd/system/friend-list";

interface IPermission {
    sendMsg:boolean,
    delete:boolean
}

@Component
export default class ViewQTargetList extends Vue {
    private countPerPage = 20;
    private countPerRow = 4;
    @Prop({type:String, required: true}) type!:string;

    private title:string = "";
    private sendMsgTitle:string = "";
    private permissions!:IPermission;
    private fullList:Array<IApiKeqingBotFriendItemData | IApiKeqingBotGroupItemData> | null = null;
    private list:Array<IApiKeqingBotFriendItemData | IApiKeqingBotGroupItemData> = [];
    private currentPage:number = 1;
    private openSendMessage:boolean = false;
    private sendLoading:boolean = false;
    private sendText:string = "";
    private sendToTarget:number = -1;

    private mounted() {
        this.refresh();
    }

    private onClickSendMessage(id:number, name:string) {
        this.sendToTarget = id;
        if(this.type == "friend") {
            this.sendMsgTitle = `给[${name}](${id})发送消息`;
        } else if(this.type == "group") {
            this.sendMsgTitle = `向群聊[${name}](${id})发送消息`;
        }
        this.openSendMessage = true;
    }

    @Watch("type")
    private onTypeChanged() {
        this.refresh();
    }

    private onSendClick() {
        if(this.type == "friend") {
            new ApiKeqingBotSendFriendMessage({
                target: this.sendToTarget,
                msg: this.sendText
            }).run().then(data => {
                this.sendText = "";
                this.sendLoading = false;
                this.$message.success("发送成功");
            }).catch(e => {
                console.error(e);
                this.sendLoading = false;
                this.$message.error(`发送失败：${e.msg}(${e.code})`);
            })
        } else if(this.type == "group") {
            new ApiKeqingBotSendGroupMessage({
                target: this.sendToTarget,
                msg: this.sendText
            }).run().then(data => {
                this.sendText = "";
                this.sendLoading = false;
                this.$message.success("发送成功");
            }).catch(e => {
                console.error(e);
                this.sendLoading = false;
                this.$message.error(`发送失败：${e.msg}(${e.code})`);
            })
        }
        this.sendLoading = true;
    }

    private onSendCancelClick() {
        if(this.sendLoading) return;
        this.openSendMessage = false;
        this.sendToTarget = -1;
        this.sendText = "";
    }

    private onPageChanged() {
        this.refreshListData();
    }

    private refresh() {
        this.fullList = null;
        this.list = [];
        if(this.type == "friend") {
            this.title = "QQ好友列表";
            new ApiKeqingBotGetFriendList().run().then(data => {
                this.fullList = data.list;
                this.permissions = data.permission;
                this.refreshListData();
            });
        } else if(this.type == "group") {
            this.title = "QQ群列表";
            new ApiKeqingBotGetGroupList().run().then(data => {
                this.fullList = data.list;
                this.permissions = data.permission;
                this.refreshListData();
            });
        }
    }

    private refreshListData() {
        let temp = this.fullList?.slice((this.currentPage - 1) * this.countPerPage, this.currentPage * this.countPerPage);
        temp = temp || [];
        this.list = temp;
    }
}
