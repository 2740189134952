import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotGetModulesList extends ApiKeqingBotCmd<IApiKeqingBotModulesListResult> implements IApi {
    public moduleId = "system";
    public action = "modules-list";
    public constructor() {
        super();
    }
}

export type IApiKeqingBotModulesListResult = {
    mods: Array<IApiKeqingBotModuleItem>,
    permissions: IApiKeqingBotModulePermissions,
}

export type IApiKeqingBotModuleItem = {
    id: string,
    name: string,
    desc: string,
}

export type IApiKeqingBotModulePermissions = {
    changeConfig: boolean,
}