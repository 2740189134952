import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotDashboardInfo extends ApiKeqingBotCmd<IApiKeqingBotDashboardInfoResult> implements IApi {
    public moduleId = "system";
    public action = "dashboard-info";
    public constructor() {
        super();
    }
}

export type IApiKeqingBotDashboardInfoResult = {
    friendCount: number,
    groupCount: number,
    modCount: number,
    cmdCount: number,
    botInfo: {
        nickname: string,
        email: string,
        age: number,
        level: number,
        sign: string,
        sex: "UNKNOWN" | "MALE" | "FEMALE"
    },
    systemInfo: {
        startTime: number
    },
    permissions: {
        change_qq_profile: boolean,
        reload_data: boolean,
        power_manage: boolean,
    },
}