import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotGetFriendList extends ApiKeqingBotCmd<IApiKeqingBotGetFriendListResult> implements IApi {
    public moduleId = "system";
    public action = "friend-list";
    public constructor() {
        super();
    }
}

export type IApiKeqingBotGetFriendListResult = {
    permission:{
        sendMsg:boolean,
        delete:boolean
    },
    list:IApiKeqingBotFriendItemData[]
}

export type IApiKeqingBotFriendItemData = {
    user_id:number,
    nickname:string,
    remark:string,
}