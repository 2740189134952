

















import {Component, Vue, Watch} from "vue-property-decorator";
import ViewCommonConfigOperate from "@/components/admin/view-common-config-operate.vue";
import CompTargetSelector from "@/components/admin/comp-target-selector.vue";
import KeqingBotConfigerOperator, {ConfigOperateMode} from "@/utils/keqing-bot-configer-operator";

type ConfigFormat = {
    allowGroups: number[],
}

@Component({
    components: {
        CompTargetSelector,
        ViewCommonConfigOperate
    }
})
export default class ViewSetuConfig extends Vue {
    private modId:string = 'moe.yumi.setu';
    private cfg!:ConfigFormat;
    private configPaths:string[] = [
        'allowGroups',
    ];
    private labelCol = {
        xs: { span: 24 },
        sm: { span: 5 },
    };
    private wrapperCol = {
        xs: { span: 24 },
        sm: { span: 12 },
    };

    private mounted():void {

    }

    private onReady(result:ConfigFormat):void {
        this.cfg = result;
    }

    private onAllowGroupsChanged(arr:number[]) {
        new KeqingBotConfigerOperator(this.modId)
            .addAction(ConfigOperateMode.SetListAll, 'allowGroups', arr)
            .submit();
    }
}
