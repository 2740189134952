























































import {Component, Ref, Vue} from "vue-property-decorator";
import {
    ApiKeqingBotGetModulesList,
    IApiKeqingBotModuleItem, IApiKeqingBotModulesListResult
} from "@/request-apis/keqing-helper/cmd/system/modules-list";
import CompModuleItem from "@/components/admin/comp-module-item.vue";

enum States {
    Loading = "loading",
    Success = "success",
    Error = "error"
}

@Component({
    components: {CompModuleItem}
})
export default class ViewModuleManage extends Vue {
    private states:States = States.Loading;
    private error:{code:number,msg:string} | null = null;
    private result:IApiKeqingBotModulesListResult | null = null;
    private searchDataSource:IApiKeqingBotModuleItem[] = [];
    private searchResult:IApiKeqingBotModuleItem[] | null = null;

    private mounted() {
        this.result = null;
        this.states = States.Loading;
        new ApiKeqingBotGetModulesList().run().then(result => {
            this.result = result;
            this.states = States.Success;
        }).catch(e => {
            this.error = e;
            this.states = States.Error;
        })
    }

    private onSearchBoxSelect(value:string) {
        this.doSearch(value);
    }

    private onSearchBoxSearch(value:string) {

    }

    private onSearchBoxChange(value:string) {
        if(!this.result) return;
        if(value) {
            let result:IApiKeqingBotModuleItem[] = [];
            for(let mod of this.result.mods) {
                if(mod.name.indexOf(value) >= 0) {
                    result.push(mod);
                }
            }
            this.searchDataSource = result;
        } else {
            this.searchResult = null;
            this.searchDataSource = [];
        }
    }

    private doSearch(value:string) {
        if(!this.result) return;
        let result:IApiKeqingBotModuleItem[] = [];
        for(let mod of this.result.mods) {
            if(mod.name.indexOf(value) >= 0) {
                result.push(mod);
            }
        }
        this.searchResult = result;
    }
}
