import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";
import IApi from "@/managers/net/i-api";
import {KeqingBotConfigOperateAction} from "@/utils/keqing-bot-configer-operator";

export type ApiKqingBotConfigOperateResult = {
    [path:string]:any
}

export class ApiKeqingBotConfigOperate extends ApiKeqingBotCmd<ApiKqingBotConfigOperateResult> implements IApi {
    public moduleId = "system";
    public action = "config-operate";
    public constructor(modId:string, actions:KeqingBotConfigOperateAction[], ext?:string) {
        super({
            mod:modId,
            cfgExt:ext,
            actions:actions,
        });
    }
}