import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotPermissionsFlagAdd extends ApiKeqingBotCmd<boolean> implements IApi {
    public moduleId = "system";
    public action = "flag-permission-add";
    public constructor(data:{
        target:number,
        flag:string,
    }) {
        super(data);
    }
}