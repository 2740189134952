import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";
import IApi from "@/managers/net/i-api";


export default class ApiKeqingBotRandomOrderAdd extends ApiKeqingBotCmd<void> implements IApi {
    public moduleId = "moe.yumi.random";
    public action = "order-add";
    public constructor(order:string) {
        super({order});
    }
}


