import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotPermissionsGetGlobalBwlist extends ApiKeqingBotCmd<Array<number>> implements IApi {
    public moduleId = "system";
    public action = "global-bwlist";
    public constructor(data:{
        type: string,
    }) {
        super(data);
    }
}