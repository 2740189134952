import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";
import {CommandBwlistType} from "@/request-apis/keqing-helper/cmd/system/command-bwlist";

export class ApiKeqingBotCommandRemoveBwlist extends ApiKeqingBotCmd<boolean> implements IApi {
    public moduleId = "system";
    public action = "command-bwlist-remove";
    public constructor(data:{
        type: CommandBwlistType,
        cmd: string,
        target: number
    }) {
        super(data);
    }
}