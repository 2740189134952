import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";
import IApi from "@/managers/net/i-api";


export default class ApiKeqingBotRandomOrderDelete extends ApiKeqingBotCmd<void> implements IApi {
    public moduleId = "moe.yumi.random";
    public action = "order-delete";
    public constructor(order:string, isAddToDeletedList:boolean) {
        super({order, isAddToDeletedList});
    }
}


