import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";
import IApi from "@/managers/net/i-api";
import {SentenceType} from "@/request-apis/keqing-helper/cmd/moe.yumi.random/sentence-list";


export type SentenceInfoResult = {
    qq: number,
    group: number,
    group_nickname: string | null,
    time: number,
}

export default class ApiKeqingBotRandomSentenceGetInfo extends ApiKeqingBotCmd<SentenceInfoResult> implements IApi {
    public moduleId = "moe.yumi.random";
    public action = "sentence-info";
    public constructor(type:SentenceType, sentence:string) {
        super({type, sentence});
    }
}


