import {ApiKeqingBotConfigOperate} from "@/request-apis/keqing-helper/cmd/system/config-operate";

export enum ConfigOperateMode {
    GetAll = 'get-all',
    Get = 'get',
    Set = 'set',
    GetNumber = 'get-number',
    SetNumber = 'set-number',
    GetBoolean = 'get-boolean',
    SetBoolean = 'set-boolean',
    GetString = 'get-string',
    SetString = 'set-string',
    GetMapAll = 'get-map-all',
    SetMapAll = 'set-map-all',
    GetMapItem = 'get-map-item',
    SetMapItem = 'set-map-item',
    RemoveMapItem = 'remove-map-item',
    GetListAll = 'get-list-all',
    SetListAll = 'set-list-all',
    GetListItem = 'get-list-item',
    SetListItem = 'set-list-item',
    InsertListItem = 'insert-list',
    RemoveListItem = 'remove-list-item',
}

export type KeqingBotConfigOperateAction = {
    mode:ConfigOperateMode,
    path:string,
    args:any[]
}

export default class KeqingBotConfigerOperator {
    private _modId:string = "";
    private _cfgExt?:string;
    private _actions:KeqingBotConfigOperateAction[] = [];

    public constructor(modId:string,ext?:string) {
        this._modId = modId;
        this._cfgExt = ext;
    }

    public addAction(mode:ConfigOperateMode.GetAll):this;
    public addAction(mode:ConfigOperateMode.Get, path:string):this;
    public addAction(mode:ConfigOperateMode.Set, path:string, value:any):this;
    public addAction(mode:ConfigOperateMode.GetNumber, path:string):this;
    public addAction(mode:ConfigOperateMode.SetNumber, path:string, value:number):this;
    public addAction(mode:ConfigOperateMode.GetBoolean, path:string):this;
    public addAction(mode:ConfigOperateMode.SetBoolean, path:string, value:boolean):this;
    public addAction(mode:ConfigOperateMode.GetString, path:string):this;
    public addAction(mode:ConfigOperateMode.SetString, path:string, value:string):this;
    public addAction(mode:ConfigOperateMode.GetMapAll, path:string):this;
    public addAction(mode:ConfigOperateMode.SetMapAll, path:string, value:{[key:string]:any}):this;
    public addAction<T = any>(mode:ConfigOperateMode.GetMapItem, path:string, key:string):this;
    public addAction<T = any>(mode:ConfigOperateMode.SetMapItem, path:string, key:string, value:T):this;
    public addAction(mode:ConfigOperateMode.RemoveMapItem, path:string, key:string):this;
    public addAction<T = any>(mode:ConfigOperateMode.GetListAll, path:string):this;
    public addAction<T = any>(mode:ConfigOperateMode.SetListAll, path:string, value:T[]):this;
    public addAction<T = any>(mode:ConfigOperateMode.GetListItem, path:string, index:number):this;
    public addAction<T = any>(mode:ConfigOperateMode.SetListItem, path:string, index:number, value:T):this;
    public addAction<T = any>(mode:ConfigOperateMode.InsertListItem, path:string, index:number, value:T):this;
    public addAction(mode:ConfigOperateMode.RemoveListItem, path:string, index:number, count:number):this;
    public addAction(mode:ConfigOperateMode, path?:string, ...args:any):this {
        if(path === undefined) path = '';
        this._actions.push({
            mode: mode, path: path, args: args
        });
        return this;
    }

    public async submit() {
        return await new ApiKeqingBotConfigOperate(this._modId, this._actions, this._cfgExt).run();
    }
}